import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const extraStyles = {
   "primary":{
      "main":"#0c446c"
   },
   "secondary":{
      "light":"#BDB46B",
      "main":"#000000",
      "contrastText":"#FFF"
   }
};
const {
  fontFamilyReadable,
  fontFamilyBrand,
} = {
  fontFamilyReadable: "Nunito Sans",
  fontFamilyBrand: "Nunito Sans",
}


let theme = createTheme({
  palette: {
    text: {
      primary: "#333",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color"s luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    // colors
    gray: "gray",
    grayLabel: "#828282",
    lighterGray: "#fafafa",
    grayF5: "#F5F5F5",
    grayC4: "#C4C4C4",
    grayE5: "#E5E5E5",
    gray96: "#969696",
    lightGray: "#eee",
    darkGray: "darkgray",
    borderLineGray: "#717171",
    menuGray: "#7C7C7C",
    disabledRow: "#F1F1F1",
    error: {
      main: "#C83636",
    },
    successGreen: "#6CBA6C",
    white: "#FFFFFF",
    black: "#000000",
    whiteMask: "rgba(255, 255, 255, 0.65)",
    gray71: "#717171",
    magenta: "#FF00FF",
    calendarFocusDay: "#a9d4ff",
    calendarSelectDay: "#e6e6e6",
    redC7: "#C70934",
    yellow: "#F2C94C",
    red: "#FF3764",
    lightRed: "#FF92B0",
    ...extraStyles
  }, //oswald para titulos
  // raleway textos
  typography: {
    fontSize: 11,
    h1: {
      fontFamily: fontFamilyBrand,
      fontSize: "28px",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: fontFamilyBrand,
      fontSize: "24px",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: fontFamilyBrand,
      fontSize: "22px",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: fontFamilyBrand,
      fontSize: "18px",
    },
    h5: {
      // sidebar dias y precio
      fontFamily: fontFamilyReadable,
      fontSize: "16px",
      fontWeight: 700,
    },
    h6: {
      // sidebar fechas
      fontFamily: fontFamilyBrand,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    subtitle1: {
      // title message with name
      fontWeight: 700,
      fontSize: "14px",
      fontFamily: fontFamilyReadable,
      lineHeight: "16px",
    },
    subtitle2: {
      fontFamily: fontFamilyReadable,
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: "14px",
    },
    body1: {
      fontFamily: fontFamilyReadable,
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "20px"
    },
    body2: {
      fontFamily: fontFamilyReadable,
      fontWeight: 500,
    },
    button: {
      fontFamily: fontFamilyBrand,
      fontSize: 14,
      fontWeight: 700,
    },
    caption: {
      // links de texto
      fontFamily: fontFamilyReadable,
      fontSize: "13px",
      lineHeight: "15px",
      fontWeight: 400,
    },
    overline: {
      fontFamily: fontFamilyReadable,
      fontSize: 11,
    },
  },
  borders: {
    0: "0",
    1: "1px solid black",
    2: "1px solid #717171",
    3: "2px solid #717171",
    4: "3px solid #ff3764",
    5: "1px solid #F1F1F1",
    6: "1px solid #C4C4C4",
    7: "2px solid #FFFFFF",
    8: "2px solid #ff89a5",
    9: "2px solid #89ffe7",
    10: "2px solid #ff3764",
    11: "1px solid #E5E5E5",
    12: "1px solid #6CBA6C",
    13: "2px solid #E5E5E5",
  },
});

theme = {
  ...theme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          backgroundColor: "white",
          //paddingRight: "0 !important",
          color: theme.palette.gray71
        },
      },
    },
    MuiButton: {
      root: {
        height: 38,
      },
      label: {
        fontWeight: 700,
        letterSpacing: "0.0075em",
        lineHeight: 1.2,
      },
      contained: {
        boxShadow: "none",
        borderRadius: 0,
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
        "&.Mui-focused": {
          color: "#000000",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      root: {
        paddingTop: theme.spacing(1),
      },
      underline: {
        "&:before": {
          display: "none",
        },
        "&:after": {
          borderColor: "white !important",
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid #000000",
      },
    },
    MuiTab: {
      root: {
        "&.Mui-selected": {
          color: theme.palette.primary.main,
        },
      },
      wrapper: {
        textTransform: "none",
        fontSize: "21px",
        fontFamily: fontFamilyBrand,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "31px",
        color: "black",
      },
    },
    MuiCheckbox: {
      root: {
        color: "transparent",
      },
    },
    MuiTextField: {},
    // table
    MuiTableCell: {
      head: {
        textTransform: "uppercase",
        fontVariant: "h6",
        borderLeft: 0,
        fontWeight: "bold",
      },
      root: {
        maxWidth: 200,
        overflow: "hidden",
        padding: theme.spacing(1) / 2,
        cursor: "pointer",
        border: 0,
        borderLeft: `1px solid ${theme.palette.darkGray}`,
        "&:first-child": {
          borderLeft: 0,
        },
      },
    },
    MuiTableHead: {
      root: {},
    },
    MuiTableRow: {
      root: {
        "&:nth-child(even)": {
          backgroundColor: theme.palette.lighterGray,
        },
        "&:nth-child(odd)": {
          backgroundColor: theme.palette.lightGray,
        },
        "&:nth-child(odd):hover": {
          backgroundColor: `${theme.palette.primary.light}`,
        },
        "&:nth-child(even):hover": {
          backgroundColor: `${theme.palette.primary.light}`,
        },
      },
      footer: {
        backgroundColor: "transparent !important",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: "bold",
        fontFamily: fontFamilyReadable,
        lineHeight: 1.4,
      },
    },
    PrivateSwitchBase: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiStepLabel: {
      root: {
        cursor: "pointer",
      },
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: "4px",
        marginRight: "4px",
      },
    },
    MuiSvgIcon: {
      root: {
        "font-size": "1em",
      },
    },
  },
};

theme = responsiveFontSizes(theme, {
  variants: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
  ],
});
export default theme;
